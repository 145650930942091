import useAxios from "@/compositions/useAxios";

const { fetchData, postData } = useAxios();

export function requestLogin(payload) {
  // 로그인 요청
  return new Promise((resolve, reject) => {
    postData(
      "/login",
      payload,
      (res) => {
        resolve(res);
      },
      (fail) => {
        reject(fail);
      }
    );
  });
}

export function requestLogout() {
  // 로그아웃 요청
  return new Promise((resolve, reject) => {
    postData(
      "/logout",
      {},
      (res) => {
        resolve(res);
      },
      (fail) => {
        reject(fail.message);
      }
    );
  });
}
