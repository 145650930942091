import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';


// 로그인 여부 확인
function checkLogin(to, from, next, alertContent = '로그인이 필요합니다.') {
 	if (store.state.user.isLogin) {
        return;
	} else {
        alert(alertContent);
		router.push({ name: 'Signin' });
	}
}

// 이미 로그인했을 시 다시 들어가는거 방지
function loginGuard(next, alertContent = '이미 로그인/회원가입 하셨습니다.') {
	if (!store.state.user.isLogin) {
		next();
	} else {
        alert(alertContent);
		router.push({ name: 'Home' });
	}
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
        beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
            next();
		},
    },
    { // Sign In
        path: '/signin',
        name: 'Signin',
        component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue'),
        beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
        props: true,
		meta: {
			title: '로그인',
		},
      },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
