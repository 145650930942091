import {
  gameSetFailResData,
  gameSetSuccessResData,
  gameStartSuccessResData,
  gameStartFailResData,
} from "@/assets/common/testData";
import useAxios from "@/compositions/useAxios";

const { fetchData, postData } = useAxios();

export function requestGameStart(payload) {
  //게임 시작 요청

  //테스트용 코드
  return new Promise((resolve, reject) => {
    //게임 시작 성공 데이터
    let data = gameStartSuccessResData;
    //게임 시작 실패 데이터
    // let data = gameStartFailResData;

    if (data.errcode === 0) {
      resolve(data);
    } else {
      reject(data);
    }
  });

  // api 수정 후 이 코드로 복귀
  // return new Promise((resolve, reject) => {
  //   postData(
  //     "/gamestart",
  //     payload,
  //     (res) => {
  //       resolve(res);
  //     },
  //     (fail) => {
  //       reject(fail);
  //     }
  //   );
  // });
}

export function requestGameSet(payload) {
  //게임 세팅 요청
  //테스트용 코드
  return new Promise((resolve, reject) => {
    // let data = gameSetSuccessResData;
    let data = gameSetFailResData;

    if (data.errcode === 0) {
      resolve(data);
    } else {
      reject(data);
    }
  });

  // api 수정 후 이 코드로 복귀
  // return new Promise((resolve, reject) => {
  //   postData(
  //     "/gameset",
  //     payload,
  //     (res) => {
  //       resolve(res);
  //     },
  //     (fail) => {
  //       reject(fail);
  //     }
  //   );
  // });
}
