import store from "../store";

export const postData = {
  methods: {
    // 해당 url로 데이터 보낸 후 응답오면 콜백 실행
    postData(url, payload, callback = () => {}, header = {}) {
      this.axios
        .post(url, payload, {})
        .then((res) => {
          if (!res.data?.success) {
            return;
          }
          callback(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
