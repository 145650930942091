import store from "@/store";
import axios from "axios";
import router from "../router";

export default function useAxios() {
  function postData(
    url,
    payload,
    callback = () => {},
    failCallback = () => {}
  ) {
    axios
      .post(url, payload)
      .then((res) => {
        if (res.data?.errcode === 0) {
          if (callback) {
            callback(res.data);
          }
        } else {
          // post에 대한 응답으로 success가 false일 경우 실행할 로직(서버와 통신은 성공한 상태)
          if (failCallback) {
            failCallback(res.data);
          }
        }
      })
      // 서버와 통신자체가 실패한 상태
      .catch((error) => {
        console.error(error);
      });
  }

  function fetchData(
    url,
    params = {},
    callback = () => {},
    failCallback = () => {}
  ) {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (res.data?.success) {
          callback(res.data);
        } else {
          if (failCallback) {
            failCallback(res.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        const status = error.message.slice(-3);
        if (status === "500") {
          router.push("/404");
        } else {
          if (status === "429") {
            router.push("/");
          } else {
            router.push("/");
          }
        }
      });
  }

  return {
    postData,
    fetchData,
  };
}
