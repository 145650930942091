import store from '@/store';

export const login = {
	// 로그인된 상태에서만 보여줄 디렉티브 ex) 로그아웃 버튼, 마이페이지
	mounted(el, _, vnode) {
		if (!store.state.user.isLogin) {
			vnode.el.parentNode.removeChild(vnode.el);
		}
	},
};
export const logout = {
	// 로그아웃된 상태에서만 보여줄 디렉티브 ex) 로그인 버튼
	mounted(el, _, vnode) {
		if (store.state.user.isLogin) {
			vnode.el.parentNode.removeChild(vnode.el);
		}
	},
};
