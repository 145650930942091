import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from '@/plugins/axios';

import fade from "./directive/fade";
import slide from "./directive/slide";
import circleMotion from "./directive/circleMotion";

import { numberonly, price, contact} from '@/directive/format';
import { login, logout } from '@/directive/login';

axios.defaults.baseURL = "https://api.binsongame.co.kr:11740/api/roulette";

const app = createApp(App);

app.directive('login', login);
app.directive('logout', logout);
app.directive("fade", fade);
app.directive("slide", slide);
app.directive("circleMotion", circleMotion);
app.directive('numberonly', numberonly);
app.directive('price', price);
app.directive('contact', contact);
app.config.globalProperties.axios = axios;
app.use(router).mount("#app");
