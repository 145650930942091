import router from '@/router';
import store from '@/store';
import axios from 'axios';

axios.interceptors.response.use(
	(response) => {
		// interceptor를 통해 백엔드 API로부터 받은 값을 먼저 처리함
		// 2xx 범위에 있는 상태코드는 여기에서 실행됨
		if (response.data.code == 99104 || response.data.code == 99105) {
			store.commit('toast/alert', '로그인이 만료되었습니다. 다시 로그인해주세요.');
			store.commit('token/clearToken');
			//store.dispatch('user/LOGOUT');
			router.push({ name: 'Signin' });
		}

		return response;
	},
	(error) => {
		// 2xx 외의 범위에 있는 상태코드는 여기에서 실행됨
		//console.log(error);
		return error;
	},
);

export default axios;
