import { requestGameStart, requestLogin, requestLogout } from "@/api/auth";
import { loginSuccessResData } from "@/assets/common/testData";
import router from "@/router";
import store from "..";

export default {
  namespaced: true,
  state: {
    userInfo: {
      uname: "",
      uphone: "",
      point: "",
      balance: "",
      gameCode: "",
    },
    isLogin: false,
  },
  mutations: {
    setLogout(state) {
      state.isLogin = false;
    },
    setLogin(state) {
      state.isLogin = true;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    LOGIN({ commit, dispatch }, { uname, uphone }) {
      return new Promise((resolve, reject) => {
        requestLogin({ uname, uphone })
          .then(async ({ data, req }) => {
            //isLogin true로 설정
            commit("setLogin");
            //user info 세팅

            //테스트 데이터 (api 수정 후 삭제 요망) - 성공 과정
            data = loginSuccessResData.data;
            req = loginSuccessResData.req;

            await commit("setUserInfo", {
              uname: req.uname,
              uphone: req.uphone,
              point: data.point,
              balance: data.balance,
            });
            router.push({ name: "Home" });
            resolve();
          })
          .catch(({ errmsg }) => {
            //로그인 실패시 메시지 출력
            reject();
            alert(errmsg);
          });
      });
    },
    async LOGOUT({ commit }) {
      // 결과에 상관없이 무조건 로그아웃 시킴
      const res = await requestLogout();
      commit("setLogout");
      router.push({ name: "Signin" });
    },
  },
};
