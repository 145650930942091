import autoContactDash from '@/compositions/autoContactDash';
import priceFormat from '@/compositions/priceFormat';

const numberonly = (el) => {
	// <input v-numberonly>
	// 숫자만 입력받고, 문자는 입력받지 않음
	el.addEventListener('input', (e) => {
		e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
	});
};

const price = (el) => {
	// <span v-price>
	// <input v-price>
	// 3자리 단위로 숫자 사이에 ,찍음
	const tagName = el.tagName;
	if (tagName === 'INPUT') {
		el.addEventListener('input', (e) => {
			e.target.value = priceFormat(e.target.value.replaceAll(',', ''));
		});
	} else {
		el.textContent = priceFormat(el.textContent);
	}
};

const contact = (el) => {
	// <input v-contact>
	// <span v-contact>
	// 전화번호를 3,4,3자리로 끊고 사이에 - 찍음
	// const tagName = el.tagName;
    el.addEventListener('input', (e) => {
        autoContactDash(e, e.target.value);
    });
	// if (tagName === 'INPUT') {
	// 	el.addEventListener('input', (e) => {
	// 		autoContactDash(e, e.target.value);
	// 	});
	// } else {
	// 	el.textContent = contactDashFormat(el.textContent);
	// }
};

export { numberonly, price, contact };