export const loginSuccessResData = {
  req: {
    uname: "홍길동",
    uphone: "01012345678",
  },
  data: {
    point: 5,
    balance: 15000,
  },
  errcode: 0,
  errmsg: "",
};

export const loginFailResData = {
  req: {
    uname: "홍길동",
    uphone: "01012345678",
  },
  errcode: 1,
  errmsg: "등록되지 않은 사용자입니다.",
};

export const gameStartSuccessResData = {
  req: {
    uname: "홍길동",
    uphone: "01012345678",
  },
  data: {
    gameCode: "1674948455762mVfe35wCEk3l41NPECq",
    point: 5,
    balance: 15000,
  },
  errcode: 0,
  errmsg: "",
};

export const gameStartFailResData = {
  req: {
    uname: "홍길동",
    uphone: "01012345678",
  },
  errcode: 1,
  errmsg: "등록되지 않은 사용자입니다.",
};

export const gameSetSuccessResData = {
  gameCode: "1674948455762mVfe35wCEk3l41NPECq",
  errcode: 0,
  errmsg: "",
};

export const gameSetFailResData = {
  gameCode: "1674948455762mVfe35wCEk3l41NPECq",
  errcode: 5,
  errmsg: "제한시간 60초가 지났습니다.",
};
