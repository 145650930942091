import { requestGameSet, requestGameStart } from "@/api/game";
import router from "@/router";
import store from "..";
export default {
  namespaced: true,
  state: {
    gameCode: "",
    result: {
      hit: "",
      hitItemName: "",
      pointBefore: 0,
      pointUsed: 0,
      pointAfter: 0,
    },
  },
  mutations: {
    setGameCode(state, payload) {
      state.gameCode = payload;
    },
    setGameResult(state, result) {
      state.result = result;
    },
  },
  actions: {
    //게임 시작 action
    GAME_START({ commit, dispatch }, { uname, uphone }) {
      return new Promise((resolve, reject) => {
        requestGameStart({ uname, uphone })
          .then(async ({ data, req }) => {
            //게임 코드 설정
            commit("setGameCode", data.gameCode);
            resolve();
          })
          .catch(({ errmsg }) => {
            //게임 시작 실패시 메시지 출력
            reject();
            //하트포인트 부족 에러는 onClickStart에서 에러 문구를 처리하기 때문에 해당 경우만 제외
            if (errmsg !== "하트포인트 부족. 게임을 시작할 수 없습니다.") {
              alert(errmsg);
            }
          });
      });
    },
    GAME_SET({ commit, dispatch }, { gameCode, result }) {
      return new Promise((resolve, reject) => {
        requestGameSet({ gameCode, result })
          .then(async ({ errcode, errmsg }) => {
            //에러 코드 및 메시지 확인
            // console.log(errcode, errmsg);
            resolve();
          })
          .catch(({ errmsg }) => {
            //게임 시작 실패시 메시지 출력
            reject();
            //하트포인트 부족 에러는 onClickStart에서 에러 문구를 처리하기 때문에 해당 경우만 제외
            if (errmsg !== "하트포인트 부족. 게임을 시작할 수 없습니다.") {
              alert(errmsg);
            }
          });
      });
    },
  },
};
