import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import game from "./modules/game";
import user from "./modules/user";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    game,
  },
  plugins: [
    createPersistedState({
      paths: [],
    }),
  ],
});
